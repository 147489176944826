import { TabHeaderComponent } from './../tab-header/tab-header.component';
import { Component, ContentChild, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss']
})
export class TabItemComponent implements OnInit {
  @ViewChild(TemplateRef) public contentTemplate!: TemplateRef<any>;
  @ContentChild(TabHeaderComponent) public itemHeader!: TabHeaderComponent;
  @Input() title: string = '';
  
  constructor() { 
  }

  ngOnInit(): void {
  }

}
