import { Component, Input, OnInit } from '@angular/core';
import { HelpContextResponse } from '../../models/help-context-response.model';
import { ContextService } from '../../services/context.service';

@Component({
  selector: 'context-help',
  templateUrl: './context-help.component.html',
  styleUrls: ['./context-help.component.scss']
})
export class ContextHelpComponent implements OnInit {

  helpContextResponse!: HelpContextResponse;
  loaded: boolean = false;
  docUrl: string = '';

  @Input()
  featureHelpId!: number;

  @Input()
  helpId!: number;

  constructor(private contextService: ContextService) { }

  ngOnInit(): void {
    this.contextService.getCustomerContext().subscribe(resp => {
      this.docUrl = resp.DocumentationUrl;
    });
  }

  getHelpContent(): void {
    if (!this.loaded) {
      this.contextService.getHelpContext(this.featureHelpId, this.helpId, this.docUrl).subscribe(resp => {
        this.helpContextResponse = resp;
        this.loaded = true;
      });
    }
  }
}
