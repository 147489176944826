import {
  ChangeDetectionStrategy,
  Component, OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AbstractControl, FormControl, NonNullableFormBuilder,
  Validators
} from '@angular/forms';
import { RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import {
  debounceTime, distinctUntilChanged, EMPTY, filter, finalize, map, NEVER, Observable, retry, Subscription, switchMap, tap
} from 'rxjs';
import { stateArray } from '../../../../shared/models/stateAbbreviation';
import { PostAccuteService } from '../../services/post-accute.service';
import { Nullable } from './../../../../core/types/nullable.type';
import { AutoCompleteAddress } from './../../models/address-autocomplete-response.model';
import { PacMedicareRating } from './../../models/pac-medicare-rating.model';
import { PacPaymentType } from './../../models/pac-payment-type-response.model';
import { PacProviderSearchRequestV2 } from './../../models/pac-provider-search-request.model';
import { TransferRecordService } from './../../services/transfer-record.service';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ValidateSelectionMade } from '../../../../shared/validators/validateSelectionMade';
import { AddressLocation } from '../../models/address-location.model';
import { PacQuicklistEntry } from '../../models/pac-quicklist-entry-model';
import { SendReferralDialogComponent } from '../send-referral-dialog/send-referral-dialog.component';

type MedicareRating = { Key: string; Value: number };

@Component({
  selector: 'pac-search',
  templateUrl: './pac-search.component.html',
  styleUrls: ['./pac-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PacSearchComponent implements OnInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger) trigger!: MatAutocompleteTrigger;
  //TODO: Break this out into different components maybe???
  //TODO: Type Reactive Form FormGroup<T>
  private subs = new Subscription();
  transferRecordId!: number;
  transferRecord: any;
  levelOfCareIds: number[] = [];
  validAddressSelection: boolean = false;
  selectedAddress: string = "";
  invalidRadiusErrorMessage = "You must have a valid radius.";
  addressIsAlreadyThePatienntAddress = "The selected address is already the patient's address.";
  noPatientAddressErrorMessage = "To enable the Fill Patient's Address button, update patient address.";
  noProvidersSelectedTooltip = "To enable the Send Request button, at least one Provider must be selected";
  patientHasRequiredPlatformFields: boolean = false;
  searchExceedsLimit: boolean = false;
  sortField: SortDescriptor[] = [{ field: 'Name', dir: 'asc' }]; // default sort by Name
  referringLocationHasQuickList: boolean = false;
  quickListFilterSettings: DropDownFilterSettings = {
    operator: "contains",
  };
  searchFormGroup = this.nonNullableformBuilder.group({
    networkGroup: this.nonNullableformBuilder.group({
      network: 1,
    }),
    quickListGroup: this.nonNullableformBuilder.group({
      quickList: [] as number[],
    }),
    criteriaGroup: this.nonNullableformBuilder.group({
      providerName: '',
      insurance: '',
      medicareRating: '',
      acceptingCovid: false,
    }),
    proximityGroup: this.nonNullableformBuilder.group({
      radius: [
        '',
        {
          validators: [
            Validators.max(3000),
            Validators.min(1),
            Validators.pattern('^[0-9]*$'),
          ],
        },
      ],
      milesFrom: [
        '',
        {
          validators: [Validators.required, ValidateSelectionMade],
        },
      ],
      stateDropDown: { value: '', disabled: false },
      restrictToState: false,
    }),
  });

  tooltipMessages: Array<string> = [];

  //TODO: Figure out what these values are...
  medicareRatingOptions: PacMedicareRating[] = [
    { Key: '1 Star and Higher', Value: 1 },
    { Key: '2 Stars and Higher', Value: 2 },
    { Key: '3 Stars and Higher', Value: 3 },
    { Key: '4 Stars and Higher', Value: 4 },
    { Key: '5 Stars', Value: 5 },
  ];

  //TODO: move this out into it's own thing
  states: String[] = stateArray;
  pageIsInitiallyLoading: boolean = false;
  gridIsLoading: boolean = false;
  gridData!: Observable<any>;
  patientState: string = 'None';
  patientAddress: string = '';
  addressSearchOptions!: Observable<AutoCompleteAddress[]>;
  insuranceSearchOptions!: Observable<PacPaymentType[]>;
  quickListSource!: PacQuicklistEntry[];
  pageSize = 10;
  buttonCount = 4;
  sizes = [5, 10, 20, 50];
  selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: 'multiple',
    drag: false,
  };

  public selectedProvidersKey(context: RowArgs): string {
    var contextObj = {
      Id: context.dataItem.Id,
      Name: context.dataItem.Name,
      LevelOfCareId: context.dataItem.LevelOfCareId,
      LevelOfCare: context.dataItem.LevelOfCare
    }
    return JSON.stringify(contextObj);
  }

  public selectedProviders: string[] = [];

  constructor(
    private nonNullableformBuilder: NonNullableFormBuilder,
    private pacService: PostAccuteService,
    private transferRecordService: TransferRecordService,
    public dialog: MatDialog
  ) {
    this.subs.add(
      this.radius.valueChanges.subscribe((radius) => {
        if (this.radius.valid && radius && Number(radius) >= 1) {
          this.enableMilesFromFieldIfItIsDisabled();
          this.tooltipMessages = this.tooltipMessages.filter(x => x !== this.invalidRadiusErrorMessage);
        } else {
          this.addInvalidRadiusToolTipMessage()
          this.milesFrom.disable();
          this.milesFrom.setValue('');
        }
      })
    );
  }
  enableMilesFromFieldIfItIsDisabled() {
    if (this.milesFrom.disabled) {
      this.milesFrom.enable();
      this.milesFrom.markAsTouched();
    }
  }
  addInvalidRadiusToolTipMessage() {
    const radiusErrorIsNotInTooltipMessages = this.tooltipMessages.indexOf(this.invalidRadiusErrorMessage) < 0;
    const noPatientAddressIsNotInTooltipMessages = this.tooltipMessages.indexOf(this.noPatientAddressErrorMessage) < 0;
    if (radiusErrorIsNotInTooltipMessages && noPatientAddressIsNotInTooltipMessages) {
      this.tooltipMessages = [...this.tooltipMessages, this.invalidRadiusErrorMessage];
    }
  }

  ngOnDestroy(): void {
    // Unsubscribes from all child subscriptions
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.pageIsInitiallyLoading = true;
    this.transferRecordId =
      this.transferRecordService.currentTransferRecordId()!;
    //TODO?: SHOULD THESE BE BROKEN DOWN INTO THEIR OWN COMPONENTS?
    this.addressSearchOptions = this.milesFrom.valueChanges.pipe(
      map((search) => search?.trim()),
      debounceTime(300),
      distinctUntilChanged(),
      filter((search) => search !== ''),
      switchMap((search) => {
        if (search) {
          return this.searchAddress(search);
        } else {
          return NEVER;
        }
      })
    );

    this.insuranceSearchOptions = this.pacService.getPaymentType();
    this.subs.add(
      this.transferRecordService
        .getById(this.transferRecordId)
        .subscribe((transferRecord) => this.setDetailsFromTransferRecord(transferRecord))
    );

  }
  setDetailsFromTransferRecord(transferRecord: any) {
    this.transferRecord = transferRecord;
    this.levelOfCareIds = transferRecord.PostAcuteLevelsOfCare.map((x: any) => x.LevelOfCareId);
    var address = transferRecord.Patient.ContactMethods.filter((c: any) => c.Type === 'ADDRESS')[0];
    this.patientAddress = this.formatAddress(address);
    this.patientState = transferRecord.Patient.ContactMethods.filter((c: any) => c.Type === 'ADDRESS')[0]?.State ?? stateArray[0];
    this.setFormToDefaultValues();
    this.patientHasRequiredPlatformFields = this.platformFieldsValidation(transferRecord);
    this.pageIsInitiallyLoading = false;
    this.setQuickListOptions(this.transferRecord?.ReferringLocation?.Location?.Id);
  }

  platformFieldsValidation(transferRecord: any): boolean {
    const hasPatientInfo = this.hasRequiredPatientInfo(transferRecord);
    const hasRefferingLocationInfo = this.hasRequiredReferringLocationInfo(transferRecord);
    const hasVisitInfo = this.hasRequiredVisitInfo(transferRecord);
    return hasPatientInfo && hasRefferingLocationInfo && hasVisitInfo;
  }

  hasRequiredVisitInfo(transferRecord: any) {
    const recordHasReferringAdmitDate = transferRecord.VisitInfo.ReferringAdmitTime != null;
    return recordHasReferringAdmitDate;
  }
  hasRequiredReferringLocationInfo(transferRecord: any) {
    const recordHasReferringLocationName = transferRecord?.ReferringLocation?.Location?.Name != null;
    const recordHasReferringLocationCity = transferRecord?.ReferringLocation?.Address?.City != null;
    const recordHasReferringLocationState = transferRecord?.ReferringLocation?.Address?.State != null;
    return recordHasReferringLocationName && recordHasReferringLocationCity && recordHasReferringLocationState;
  }

  hasRequiredPatientInfo(transferRecord: any) {
    const userHasFirstName = transferRecord?.Patient?.FirstName != "";
    const userHasLastName = transferRecord?.Patient?.FirstName != "";
    const userHasBirthdate = transferRecord?.Patient?.BirthDate != null;
    const userHasAtLeastOneMRN = transferRecord?.Patient?.MRNs?.length > 0;
    return userHasFirstName && userHasLastName && userHasAtLeastOneMRN && userHasBirthdate;
  }

  setQuickListOptions(locationId: number | undefined) {
    if (locationId) {
      this.subs.add(
        this.pacService.getQuickListForLocation(locationId)
          .subscribe((quickListOptions) => {
            this.referringLocationHasQuickList = quickListOptions?.length > 0;
            this.quickListSource = quickListOptions;
          })
      );
    }
    else {
      this.referringLocationHasQuickList = false;
    }
  }

  searchAddress(search: string): Observable<AutoCompleteAddress[]> {
    return this.pacService.providerAddressSearch(search).pipe(
      retry(3),
      tap((x) => this.setErrorIfNoResultsFound(x))
    );
  }

  setErrorIfNoResultsFound(x: AutoCompleteAddress[]): void {
    if (x.length === 0) {
      this.milesFrom.setErrors({ NoAddressesFound: true });
    }
    if (this.milesFrom.value === this.patientAddress) {
      this.trigger.openPanel();
    }
    else {
      this.tooltipMessages = this.tooltipMessages.filter(x => x !== this.addressIsAlreadyThePatienntAddress);
    }
  }

  formatAddress(address: any): string {
    var results = '';
    var addressArray: Array<string> = [];
    if (address?.Street1) addressArray.push(address.Street1);
    if (address?.City) addressArray.push(address.City);
    if (address?.State) addressArray.push(address.State);
    if (address?.ZipCode) addressArray.push(address.ZipCode);
    if (address?.Country) addressArray.push(address.Country);

    addressArray.forEach((addressPart) => (results += addressPart + ', '));
    // remove the last comma and space
    results = results.substring(0, results.length - 2);

    if (results === "") {
      this.tooltipMessages = [this.noPatientAddressErrorMessage];
    }

    return results;
  }

  searchProvider(): void {
    this.searchExceedsLimit = false;
    this.gridIsLoading = true;
    this.gridData = EMPTY;
    if (this.radius.value !== '' && this.isExpandedSearch()) {
      this.searchByRadius();
      return;
    }

    let searchRequest: PacProviderSearchRequestV2 = this.createDefaultSearchRequest();
    this.restrictToStateIfNecessary(searchRequest);
    this.getPostAcuteProviders(searchRequest);

  }

  searchByRadius(): void {
    this.pacService.geocodeAddress(this.selectedAddress).subscribe(addressLocation => {
      let searchRequest: PacProviderSearchRequestV2 = this.createDefaultSearchRequest();
      this.populateProximityFields(searchRequest, addressLocation);

      this.getPostAcuteProviders(searchRequest);
    });
  }

  getPostAcuteProviders(searchRequest: PacProviderSearchRequestV2) {
    this.gridData = this.pacService.searchPostAcuteCareV2(
      this.transferRecordId,
      searchRequest
    ).pipe(
      tap(x => this.searchExceedsLimit = x.TotalPageCount > 1),
      map(x => x.Facilities),
      finalize(() => this.gridIsLoading = false),
    );
  }
  populateProximityFields(searchRequest: PacProviderSearchRequestV2, addressLocation: AddressLocation) {
    this.restrictToStateIfNecessary(searchRequest);
    searchRequest.RadiusDistance = Number(this.radius.value);
    searchRequest.Longitude = addressLocation.longitude;
    searchRequest.Latitude = addressLocation.latitude;
  }
  restrictToStateIfNecessary(searchRequest: PacProviderSearchRequestV2) {
    if (this.restrictToState.value === true) {
      searchRequest.State = this.stateDropDown.value;
    }
  }
  createDefaultSearchRequest(): PacProviderSearchRequestV2 {
    let request =

    {
      City: null,
      CmsRating: this.medicareRating.value.Value as Nullable<number>,
      AcceptingCovid19: this.acceptingCovid19Patients.value,
      Latitude: null,
      Longitude: null,
      PaymentTypeIds: this.insurance,
      ProviderName: this.providerName.value === '' ? null : this.providerName.value,
      QuickListRegionIds: this.hasQuickListSelected ? this.quickList.value : null,
      RadiusDistance: null,
      State: null,
      ZipCode: null,
      StartRecord: 1,
      EndRecord: 1000,
      SortRules: '[]',
      levelOfCareIds: this.levelOfCareIds,
    };
    if (this.isExpandedSearch()) {
      //for expanded search, don't send quick list
      request.QuickListRegionIds = null;
    }
    else {
      //for quick list, don't send values not displayed
      request.CmsRating = null;
      request.AcceptingCovid19 = false,
      request.PaymentTypeIds = null,
      request.ProviderName = null;
    }
    return request;
  }

  get providerName(): FormControl<string> {
    return this.searchFormGroup.get(
      'criteriaGroup.providerName'
    ) as FormControl<string>;
  }

  get insurance(): Nullable<number[]> {
    let insuranceValueFromDropDown = this.getValueFromForm<PacPaymentType>(
      this.searchFormGroup.get('criteriaGroup.insurance')
    );
    return [insuranceValueFromDropDown?.id!];
  }

  get medicareRating(): FormControl<MedicareRating> {
    return this.searchFormGroup.get(
      'criteriaGroup.medicareRating'
    ) as FormControl<any>;
  }

  get acceptingCovid19Patients(): FormControl<boolean> {
    return this.searchFormGroup.get(
      'criteriaGroup.acceptingCovid'
    ) as FormControl<boolean>;
  }

  get radius(): FormControl<string> {
    return this.searchFormGroup.get(
      'proximityGroup.radius'
    ) as FormControl<string>;
  }

  get restrictToState(): FormControl<boolean> {
    return this.searchFormGroup.get(
      'proximityGroup.restrictToState'
    ) as FormControl<boolean>;
  }

  get stateDropDown(): FormControl<string> {
    return this.searchFormGroup.get(
      'proximityGroup.stateDropDown'
    ) as FormControl<string>;
  }

  get milesFrom(): FormControl<string> {
    return this.searchFormGroup.get(
      'proximityGroup.milesFrom'
    ) as FormControl<string>;
  }

  get network(): boolean {
    return (
      this.getValueFromForm<boolean>(
        this.searchFormGroup.get('networkGroup.network')
      ) ?? false
    );
  }
  get quickList(): FormControl<number[]> {
    const quickListFromMultiSelect = this.searchFormGroup.get(
      'quickListGroup.quickList'
    );
    return quickListFromMultiSelect as unknown as FormControl<number[]>;
  }

  get hasQuickListSelected(): boolean {
    return this.quickList.value?.length ? true : false;
  }

  get stateDropDownEnabled(): boolean {
    return (!this.radius.value || !this.radius.valid || !this.milesFrom.valid);
  }

  private getValueFromForm<T>(
    formControl: AbstractControl | null
  ): Nullable<T> {
    const value = formControl?.value;
    if (!value) {
      return null;
    }
    return value;
  }

  setFormToDefaultValues(): void {
    this.searchFormGroup.reset();
    if (this.tooltipMessages.indexOf(this.noPatientAddressErrorMessage) < 0) {
      this.tooltipMessages = [this.invalidRadiusErrorMessage];
    }
    this.gridData = EMPTY;
    this.searchFormGroup.patchValue({
      proximityGroup: {
        restrictToState: false,
        stateDropDown: this.patientState,
      },
    });
    this.selectedProviders = [];
    this.searchExceedsLimit = false;
  }

  isExpandedSearch(): boolean {
    const expandedSearch = this.searchFormGroup.get('networkGroup.network')?.value == 1;
    return expandedSearch;
  }

  searchDisabled(): boolean {
    if (this.isExpandedSearch()) {
      return !this.searchFormGroup.valid || !this.patientHasRequiredPlatformFields ||
        (this.providerName.value === '' && this.radius.value === '' && this.restrictToState.value === false);
    }
    else {
      return !this.patientHasRequiredPlatformFields || !this.hasQuickListSelected;
    }
  }

  openDialog(): void {
    if (this.selectedProviders.length == 0) {
      // TODO: replace this with a fancy angular material alert dialog
      alert('you must select one or more providers before sending requests');
    }
    else {
      const dialogRef = this.dialog.open(SendReferralDialogComponent, {
        height: '100%',
        width: 'auto',
        minWidth: 'calc(100% - 100px)',
        maxHeight: 'calc(100vh - 100px)',
        data: {
          patient: this.transferRecord.Patient,
          transferRecordId: this.transferRecordId,
          message: '',
          providers: this.selectedProviders,
          documents: []
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
  }

  valueSelected(event: MatAutocompleteSelectedEvent) {
    this.milesFrom.userSelected = true;
    this.selectedAddress = event.option.value;
    this.pacService.geocodeAddress(this.selectedAddress).subscribe(addressLocation => {
      this.stateDropDown.setValue(addressLocation.stateAbbreviation || '');
      this.milesFrom.updateValueAndValidity();
    });
  }

  fillAddress() {
    this.milesFrom.userSelected = false;
    this.milesFrom.setValue(this.patientAddress);
    this.tooltipMessages = [...this.tooltipMessages, this.addressIsAlreadyThePatienntAddress];
  }
}
