import { PacPaymentType } from './../models/pac-payment-type-response.model';
import { AutoCompleteAddress } from './../models/address-autocomplete-response.model';
import {
  PacProviderSearchRequestV2,
} from './../models/pac-provider-search-request.model';
import { Observable } from 'rxjs';
import { ApiService } from './../../../core/services/api.service';
import { Injectable } from '@angular/core';
import { ApiVersion } from 'src/app/core/enums/api-version';
import { PacProviderSearchResponse } from '../models/pac-provider-search-response.model';
import { SendReferralsRequest } from '../models/send-referrals-request.model';
import { AddressLocation } from '../models/address-location.model';
import { PacQuicklistEntry } from '../models/pac-quicklist-entry-model';

//TODO: Add Types
@Injectable({
  providedIn: 'root',
})
export class PostAccuteService {
  constructor(private apiService: ApiService) {}

  public searchPostAcuteCareV2(
    transferRecordId: number,
    searchData: PacProviderSearchRequestV2
  ): Observable<PacProviderSearchResponse> {
    let returnData = this.apiService.post<
      PacProviderSearchRequestV2,
      PacProviderSearchResponse
    >(
      ApiVersion.V1,
      `PostAcuteProviderV2/SearchProviders/?transferRecordId=${transferRecordId}`,
      searchData
    );
    return returnData;
  }

  public providerAddressSearch(
    search: string
  ): Observable<AutoCompleteAddress[]> {
    let searchResults = this.apiService.get<AutoCompleteAddress[]>(
      ApiVersion.V1,
      `PostAcuteProvider/AutocompleteAddress/?address=${search}`
    );
    return searchResults;
  }

  public geocodeAddress(
    search: string
  ): Observable<AddressLocation> {
    let searchResults = this.apiService.get<AddressLocation>(
      ApiVersion.V1,
      `PostAcuteProvider/GeocodeAddress/?address=${search}`
    );
    return searchResults;
  }

  public getPaymentType(): Observable<PacPaymentType[]> {
    let results = this.apiService.get<PacPaymentType[]>(
      ApiVersion.V1,
      `PostAcuteProvider/GetPaymentTypes`
    );
    return results;
  }

  public sendReferrals(requestData: SendReferralsRequest): Observable<any> {
    let results = this.apiService.post<SendReferralsRequest, any>(
      ApiVersion.V1,
      'PostAcuteReferralV2/SendReferral',
      requestData
    );
    return results;
  }

  public getQuickListForLocation(
    locationId: number
  ): Observable<PacQuicklistEntry[]> {
    let quickLists = this.apiService.get<PacQuicklistEntry[]>(
      ApiVersion.V1,
      `AffiliatedNetwork/GetAffiliatedNetworksForLocation/?locationId=${locationId}`
    );
    return quickLists;
  }
}
