import { AuthenticationService } from './../services/authentication.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, Subject, mergeMap, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private authService: AuthenticationService) {
  }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.isAntiForgeryTokenSet();
  }
  
  async isAntiForgeryTokenSet(): Promise<boolean> {
    let hasAntiToken: boolean = false;
    if(this.authService.hasAntiForgeryToken) {
      hasAntiToken = true ;
    } else {
      await firstValueFrom(this.authService.getAntiForgeryToken());
      let userContext = await firstValueFrom(this.authService.getUserContext());
      if(userContext){
        this.authService.isLoggedIn = true;
      }
      hasAntiToken= true;
    }
    return hasAntiToken;
  }
}
