<div class="send-referral-dialog">
  <div mat-dialog-actions class="close">
    <button mat-dialog-close>X</button>
  </div>

  <div class="navigationBar">
    <div class="header">
      <h2>Send Referrals for {{ patientName }}</h2>
    </div>
    <div class="navigation">1-2-3 Navigation placeholder</div>
  </div>

  <div mat-dialog-content class="dialog-content">

    <div class="mainContent">
      <div class="providersServices">
        <div class="header">
          <h3><b>Providers / Services</b></h3>
        </div>
        <div class="content">
          <div>(The following is temporary, informational content until this pane is fully built out)</div>
          <div class="providers"><b>Providers / LOC:</b></div>
          <ul>
            <li *ngFor="let provider of data.providers">
              {{ getProviderNameAndLOC(provider) }}
            </li>
          </ul>
        </div>
      </div>
    
      <div *ngIf="step1Visible" class="step step1">
        <div class="header">
          <h3><b>Documents</b></h3>  
        </div>
        <div class="content">
        </div>
      </div>
    
      <div *ngIf="step2Visible" class="step step2">
        <div class="header">
          <h3><b>Comments</b></h3>  
        </div>
        <div class="content">
        </div>
      </div>
    
      <div *ngIf="step3Visible" class="step step3">
        <div class="header">
        </div>
        <div class="content">
        </div>
      </div>
    </div>

  </div>

  <div class="actionBar">
    <div class="errors">
      <div *ngIf="errorMessage !== ''" class="error-message">
        <span>Oh no! {{ errorMessage }}</span>
      </div>
    </div>

    <button mat-raised-button cdkFocusInitial color="primary" class="next" (click)="onAddComments()">Next - Add Comments</button>
  </div>
</div>