import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { HomeComponent } from './components/home/home.component';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayGroundComponent } from './components/play-ground/play-ground.component';
import { ThemesComponent } from './components/themes/themes.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    HomeComponent,
    AutoCompleteComponent,
    PlayGroundComponent,
    ThemesComponent,
  ],
  exports: [
    HomeComponent,
    AutoCompleteComponent,
    PlayGroundComponent,
    ThemesComponent,
  ],
})
export class PlayGroundModule {}
