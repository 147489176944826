<div *ngIf="pageIsInitiallyLoading" class="loading">
  <mat-spinner color="primary"></mat-spinner>
</div>
<div *ngIf="!pageIsInitiallyLoading" class="container">
  <form id="pacSearchForm" [formGroup]="searchFormGroup">

    <mat-card>
      <mat-card-content>
        <div class="search-container">
          <!-- Network Group -->
          <div class="network-search-container">
            <div formGroupName="networkGroup">
              <div class="pac-radio-group">
                <label>Network: </label>
                <mat-radio-group formControlName="network">
                  <mat-radio-button class="pac-radio-button" [value]="1">
                    Expanded
                  </mat-radio-button>
                  <mat-radio-button class="pac-radio-button" [value]="2" [disabled]="!referringLocationHasQuickList">
                    Quick Lists
                  </mat-radio-button>
                </mat-radio-group>
                <div class="pac-search-help">
                  <context-help [featureHelpId]="1" [helpId]="50"></context-help>
                </div>
              </div>
            </div>
          </div>
          <!-- Criteria Group -->
          <div class="provider-search-container"
               *ngIf="isExpandedSearch(); else quickList">
            <div formGroupName="criteriaGroup" class="provider-group">
              <mat-form-field>
                <mat-label> Provider Name</mat-label>
                <input matInput formControlName="providerName" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>Insurance</mat-label>
                <mat-select formControlName="insurance">
                  <mat-option [value]="null">
                    --
                  </mat-option>
                  <mat-option *ngFor="let insuranceType of insuranceSearchOptions | async" [value]="insuranceType">
                    {{ insuranceType.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Medicare Rating</mat-label>
                <mat-select formControlName="medicareRating">
                  <mat-option [value]="null">
                    --
                  </mat-option>
                  <mat-option *ngFor="let medicareType of medicareRatingOptions" [value]="medicareType">
                    {{ medicareType.Key }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-checkbox class="search-checkbox" formControlName="acceptingCovid">Accepting COVID-19 Patients</mat-checkbox>
            </div>
          </div>
          <!-- Proximity Group -->
          <div class="proximity-search-container" *ngIf="isExpandedSearch()">
            <div formGroupName="proximityGroup" class="proximity-group">
              <label>Search by Proximity</label>

              <mat-form-field>
                <mat-label>Radius</mat-label>
                <input matInput (keypress)="($event.charCode >= 48 && $event.charCode < 58)" formControlName="radius" />
              </mat-form-field>

              <div *ngIf="radius.invalid && (radius.dirty || radius.touched)" class="warning-text">
                Radius can be empty or whole numbers 1-3000.
              </div>

              <div class="miles-container">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Miles From</mat-label>
                  <input type="text" placeholder="Search from this address" aria-label="Number" matInput formControlName="milesFrom"
                         [matAutocomplete]="auto" />
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="valueSelected($event)">
                    <mat-option *ngFor="let option of addressSearchOptions | async" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <div matTooltipPosition="above" matTooltipClass="tooltip" [matTooltip]="tooltipMessages | displayDisabledButtonToolTipMessages" [matTooltipDisabled]="tooltipMessages.length === 0">
                  <button [disabled]="tooltipMessages.length > 0"
                          mat-raised-button color="primary" (click)="fillAddress()">
                    Fill Patient's address
                  </button>
                </div>
              </div>

              <div *ngIf="milesFrom.hasError('NoAddressesFound')" class="warning-text">
                No results found.
              </div>

              <div *ngIf="milesFrom.invalid && !milesFrom.hasError('NoAddressesFound')" class="warning-text">
                You must select a valid address from the dropdown.
              </div>

              <mat-checkbox formControlName="restrictToState" class="search-checkbox">
                Restrict Results to State
              </mat-checkbox>

              <mat-form-field class="state-field">
                <mat-label>State</mat-label>
                <mat-select formControlName="stateDropDown" [disabled]="!stateDropDownEnabled">
                  <mat-option *ngFor="let state of states" [value]="state">
                    {{ state }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="search-button-container">
            <button mat-raised-button color="primary" class="pac-search-button"
                    [disabled]="searchDisabled()"
                    (click)="searchProvider()">
              Search
            </button>
            <context-help [featureHelpId]="1" [helpId]="47"></context-help>
            <a title="Reset search form to default settings and clear any selected facilities."
               (click)="setFormToDefaultValues()" style="margin-left: 10px">Reset</a>
            <span *ngIf="searchExceedsLimit" class="search-exceeds-limit-warning">
              Over 1,000 records for this search.  Please add more criteria to your search or continue working with only these records.
            </span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <ng-template #quickList>
      <div class="quickList-wrapper" formGroupName="quickListGroup">
        <kendo-multiselect formControlName="quickList"
                           placeholder="Select Quick Lists..."
                           [data]="quickListSource"
                           [filterable]="true"
                           [kendoDropDownFilter]="quickListFilterSettings"
                           textField="AffiliatedNetworkName"
                           valueField="AffiliatedNetworkId"
                           [valuePrimitive]="true"
                           [rounded]="'large'"
                           [fillMode]="'solid'"
                           [size]="'large'">
        </kendo-multiselect>
      </div>
    </ng-template>
  </form>

  <div class="grid-container">
    <div class="kendo-grid-container">
      <mat-card>
        <mat-card-content>
          <div class="kendo-button-div">
            <div class="kendo-send-request-button">
              <div matTooltipPosition="above" matTooltipClass="tooltip" [matTooltip]="noProvidersSelectedTooltip" [matTooltipDisabled]="selectedProviders.length > 0">
                <button [disabled]="selectedProviders.length == 0"
                mat-raised-button color="primary" (click)="openDialog()">Send Request</button>
              </div>
            </div>
          </div>

          <kendo-grid
            class="pac-search-grid"
            [kendoGridBinding]="gridData | async"
            [filterable]="true"
            [sortable]="true"
            [sort]="sortField"
            [pageable]="true"
            [pageSize]="pageSize"
            [selectable]="selectableSettings"
            [kendoGridSelectBy]="selectedProvidersKey"
            [(selectedKeys)]="selectedProviders"
            [loading]="gridIsLoading"
          >
            <kendo-grid-checkbox-column
              [showSelectAll]="true"
              [width]="65"
            ></kendo-grid-checkbox-column>
            <kendo-grid-column field="Name" title="Provider / Service">
            </kendo-grid-column>
            <kendo-grid-column field="LevelOfCare" title="Level of Care">
            </kendo-grid-column>
            <kendo-grid-column field="BedCount" title="Bed Information"></kendo-grid-column>
            <kendo-grid-column field="Address" title="Address"></kendo-grid-column>
            <kendo-grid-column field="PhoneNumber" title="Phone"></kendo-grid-column>
            <kendo-grid-column field="FaxNumber" title="Fax"></kendo-grid-column>
            <kendo-grid-column field="CmsRating" title="CMS Overall Rating"></kendo-grid-column>
            <kendo-grid-column field="Distance" title="Distance"></kendo-grid-column>
            <ng-template kendoGridLoadingTemplate>
              <div class="loading-container">
                <mat-spinner [diameter]="25" color="primary"></mat-spinner>
              </div>
            </ng-template>
            <ng-template  kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
              <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
              <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
              <kendo-pager-next-buttons></kendo-pager-next-buttons>
              <kendo-pager-input></kendo-pager-input>
              <kendo-pager-info></kendo-pager-info>
              <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
            </ng-template>
          </kendo-grid>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
