import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiVersion } from 'src/app/core/enums/api-version';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class TransferRecordService {
  constructor(private apiService: ApiService, private router: ActivatedRoute) {}

  public getById(id: number): Observable<any> {
    let searchResults = this.apiService.post(
      ApiVersion.V1,
      `TransferRecord/Get/${id}`
    );
    return searchResults;
  }

  public currentTransferRecordId(): number | null {
    let currentTransferRecordId =
      this.router.snapshot.queryParamMap.get('transferrecordid');
    if (currentTransferRecordId) {
      return Number(currentTransferRecordId);
    }
    return null;
  }
}
