import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayDisabledButtonToolTipMessages'
})
export class DisplayDisabledButtonToolTipMessages implements PipeTransform {

  transform(tooltipMessages: string[]): string {
    var results = '';
    tooltipMessages.forEach(
      (message) => (results += '• ' + message + '\n')
    );
    return results;
  }

}
