import { TabItemComponent } from './tab-item/tab-item.component';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  QueryList,
} from '@angular/core';
import { timer } from 'rxjs';

@Component({
  selector: 'tab-view',
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss'],
})
export class TabViewComponent implements AfterContentInit {
  @ContentChildren(TabItemComponent)
  private contentItems: QueryList<TabItemComponent> = new QueryList<TabItemComponent>();

  public appItems: QueryList<TabItemComponent> =
    new QueryList<TabItemComponent>();

  constructor() {}

  ngAfterContentInit() {
    timer(0).subscribe(() => {
      this.appItems = this.contentItems;
    });
  }
}
