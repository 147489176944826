import { ApiVersion } from '../enums/api-version';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private toastr: ToastrService) {}

  private formatErrors(err: any) {
    this.toastr.error(err.error.Message, 'You\'ve encountered an error:', {
      timeOut: 3000,
    });
    return throwError(() => err.error);
  }

  get<Type>(
    version: ApiVersion,
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<Type> {
    //TODO: Move this to an interceptor
    let baseUrl: string;
    if (version == ApiVersion.V1) {
      baseUrl = environment.v1Api_url;
    } else {
      baseUrl = environment.v2Api_url;
    }

    return this.http
      .get<Type>(`${baseUrl}/${path}`, { params })
      .pipe(catchError(error => this.formatErrors(error)));
  }

  put<Type>(
    version: ApiVersion,
    path: string,
    body: Object = {}
  ): Observable<Type> {
    let baseUrl: string;
    if (version == ApiVersion.V1) {
      baseUrl = environment.v1Api_url;
    } else {
      baseUrl = environment.v2Api_url;
    }

    return this.http
      .put<Type>(`${baseUrl}/${path}`, JSON.stringify(body))
      .pipe(catchError(error => this.formatErrors(error)));
  }

  post<RequestType, ResponseType>(
    version: ApiVersion,
    path: string,
    body: RequestType = {} as RequestType,
    usePathOnly: boolean = false
  ): Observable<ResponseType> {
    let baseUrl: string;
    if (version == ApiVersion.V1) {
      baseUrl = environment.v1Api_url;
    } else if (version == ApiVersion.noapiV1) {
      baseUrl = environment.v1Api_url_without_api;
    } else {
      baseUrl = environment.v2Api_url;
    }
    const fullpath = usePathOnly ? path : `${baseUrl}/${path}`;

    return this.http
      .post<ResponseType>(fullpath, JSON.stringify(body))
      .pipe(catchError(error => this.formatErrors(error)));
  }

  delete<Type>(version: ApiVersion, path: string): Observable<Type> {
    let baseUrl: string;
    if (version == ApiVersion.V1) {
      baseUrl = environment.v1Api_url;
    } else {
      baseUrl = environment.v2Api_url;
    }

    return this.http
      .delete<Type>(`${baseUrl}/${path}`)
      .pipe(catchError(error => this.formatErrors(error)));
  }
}
