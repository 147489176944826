<img src="./assets/images/infoButton.png" class="contextHelp" mat-button [matMenuTriggerFor]="helpDialog" #menuTrigger (click)="getHelpContent()">
<mat-menu #helpDialog="matMenu" class="helpPopupMatMenu">
    <div class="helpPopupContainer">
        <div class="helpPopupOuterContent">
            <div *ngIf="loaded" class="helpPopupContent" [innerHTML]="helpContextResponse.Text"></div>
            <div *ngIf="!loaded" class="helpPopupContent">
                <div class="helpPopupLoadingSpinner">
                    <img src="./assets/images/spinner.gif" />
                    <span>Loading... </span>
                </div>
            </div>
        </div>
        <div class="helpPopupFooter">
            <span>HelpId: {{ featureHelpId }}:{{ helpId }}</span>
        </div>
    </div>
</mat-menu>
