import { ProviderSearchResponse } from '../../models/provider-search-response';
import { ApiService } from '../../../../core/services/api.service';
import { Component, OnInit } from '@angular/core';
import { ApiVersion } from 'src/app/core/enums/api-version';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { LocationTypeModel } from '../home/home.component';
import {
  combineLatest,
  debounce,
  debounceTime,
  distinctUntilChanged,
  filter,
  flatMap,
  interval,
  map,
  mergeMap,
  Observable,
  retry,
  startWith,
  switchMap,
} from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
})
export class AutoCompleteComponent implements OnInit {
  public providerResponse: ProviderSearchResponse[] | null = null;
  public dataSource: any = new LocationTypeDataSource(this.apiService);
  displayedColumns = ['Name', 'Id', 'Identifier'];

  control = new FormControl('');
  streets: string[] = [
    'Champs-Élysées',
    'Lombard Street',
    'Abbey Road',
    'Fifth Avenue',
  ];
  filteredStreets: Observable<string[]> = new Observable<string[]>();
  providerResults: Observable<ProviderSearchResponse[]> = new Observable<
    ProviderSearchResponse[]
  >();

  constructor(private apiService: ApiService) {
    this.providerResults = this.control.valueChanges.pipe(
      map((search) => search?.trim()),
      debounceTime(200),
      distinctUntilChanged(),
      filter((search) => search !== ''),
      switchMap((search) => {
        let searchBody = {
          startRecord: 1,
          endRecord: 101,
          filters: `[{'field':'KEY','filter':'${search}','caseSensitive':false,'dataType':'STRING'}]`,
          activeOnly: true,
          searchTerm: search,
          includeDeleted: false,
          includePending: false,
          onlyPending: false,
        };
        return this.apiService
          .post<any, ProviderSearchResponse[]>(
            ApiVersion.V1,
            `Provider/Search`,
            searchBody
          )
          .pipe(retry(3), startWith([]));
      })
    );
  }

  ngOnInit(): void {}

  public makeProviderSearch(): void {
    let testBody = {
      startRecord: 1,
      endRecord: 101,
      filters:
        "[{'field':'KEY','filter':'TEST','caseSensitive':false,'dataType':'STRING'}]",
      activeOnly: true,
      searchTerm: 'TEST',
      includeDeleted: false,
      includePending: false,
      onlyPending: false,
    };

    this.apiService
      .post<any, ProviderSearchResponse[]>(
        ApiVersion.V1,
        `Provider/Search`,
        testBody
      )
      .subscribe((data) => {
        this.providerResponse = data;
        console.log(this.providerResponse);
      });
  }

  locationDataSource(): Observable<LocationTypeModel> {
    return this.apiService.post<any, LocationTypeModel>(
      ApiVersion.V1,
      `LocationType/GetAllLocationTypes`
    );
  }
}

export class LocationTypeDataSource extends DataSource<LocationTypeModel> {
  constructor(private apiService: ApiService) {
    super();
  }
  connect(collectionViewer: CollectionViewer): Observable<LocationTypeModel[]> {
    return this.apiService.post<any, LocationTypeModel[]>(
      ApiVersion.V1,
      `LocationType/GetAllLocationTypes`
    );
  }
  disconnect(collectionViewer: CollectionViewer): void {}
}
