import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'tab-header',
  templateUrl: './tab-header.component.html',
  styleUrls: ['./tab-header.component.scss'],
})
export class TabHeaderComponent implements OnInit {
  @ViewChild(TemplateRef) public headerTemplate!: TemplateRef<any>;
  @Input() title: string = '';

  constructor() {}

  ngOnInit(): void {}
}
