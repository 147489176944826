import { PacModule } from './features/pac/pac.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PlayGroundModule } from './features/play-ground/play-ground.module';

@NgModule({
  imports: [
    AppRoutingModule,
    CoreModule,
    SharedModule, 
    PlayGroundModule,
    PacModule
  ],
  declarations: [AppComponent],
  providers: [
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
