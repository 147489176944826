import { CookieTypes } from './../enums/cookie-types';
import { AuthenticationService } from '../services/authentication.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiVersion } from '../enums/api-version';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let isLoggedIn = this.authService.isLoggedIn;
    let isV1Api = request.url.includes(ApiVersion.V1) || request.url.includes('/api');

    if (isLoggedIn && isV1Api) {
      let xsfCookie = this.authService.getCookieByName(CookieTypes.XSRF_TOKEN_V1);
      
      //TODO: Add customer name to cookie to pull it out 
      //TODO: Create another interceptor to pull out the V1 and V2 to direct to the correct API
      let customerName = this.authService.getCookieByName(CookieTypes.CustomerName);
      request.url.replace(`{customerId}`, customerName);

      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Origin': '*',
        'X-XSRF-TOKEN': xsfCookie
      }

      request = request.clone({
        setHeaders: headerDict
      });
    }
    else{
      let xsfCookie = this.authService.getCookieByName('XSRF-TOKEN');
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Origin': '*',
        'X-XSRF-TOKEN': xsfCookie
      }
  
      request = request.clone({
        setHeaders: headerDict
      })
    }

    return next.handle(request);
  }
}
