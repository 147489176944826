import { SharedModule } from './../../shared/shared.module';
import { PacHomeComponent } from './components/pac-home/pac-home.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PacRoutingModule } from './pac-routing.module';
import { PacSearchComponent } from './components/pac-search/pac-search.component';
import { SendReferralDialogComponent } from './components/send-referral-dialog/send-referral-dialog.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns'



@NgModule({
  imports: [
    CommonModule,
    PacRoutingModule,
    SharedModule,
    DropDownsModule,
  ],
  declarations: [PacHomeComponent, PacSearchComponent, SendReferralDialogComponent],
  exports: [PacHomeComponent]
})
export class PacModule { }
