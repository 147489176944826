import { ApiVersion } from '../enums/api-version';
import { ApiService } from './api.service';
import { UserContext } from '../models/user-context.model';
import { User } from './../models/user.model';
import { Observable, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  userContext: UserContext | null = null;
  private _isLoggedIn: boolean = false;
  private _hasAntiForgeryToken: boolean = false;

  constructor(
    private cookieService: CookieService,
    private apiService: ApiService
  ) {}

  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  public set isLoggedIn(value: boolean) {
    this._isLoggedIn = value;
  }

  public get hasAntiForgeryToken(): boolean {
    return this._hasAntiForgeryToken;
  }

  public set hasAntiForgeryToken(value: boolean) {
    this._hasAntiForgeryToken = value;
  }

  public getAntiForgeryToken(): Observable<any> {
    return this.apiService.get(ApiVersion.V2, `Preload/AntiForgery`);
  }

  public getCookieByName(name: string): string {
    return this.cookieService.get(name);
  }

  public getUserContext(): Observable<UserContext> {
    return this.apiService.get<UserContext>(ApiVersion.V2, `User/Context`);
  }

  private isPlatformCookieSet(): boolean {
    if (this.cookieService.get('cl.platformcookie')) {
      return true;
    }
    return false;
  }

  private login(userName: string, pass: string): Observable<User> {
    return this.apiService.post<any, User>(ApiVersion.V2, `Login`, {
      username: `${userName}`,
      secret: `${pass}`,
      sendCode: false,
    });
  }

  // public loginContext(): Observable<boolean> {
  //   let isLoggedIn = new Subject<boolean>()
  //   this.getUserContext().subscribe((userContext) => {
  //     if (userContext?.id) {
  //       this._isLoggedIn = true;
  //       isLoggedIn.next(true)
  //     } else {
  //       this._isLoggedIn = false;
  //       isLoggedIn.next(false);
  //     }
  //   });
  //   return isLoggedIn;
  // }

  private setCookie(cookieName: string, value: string, expiration?: Date) {
    let cookie = `${cookieName}=${value};path=/;domain=${window.location.hostname};`;
    if (expiration) {
      cookie += `expires=${expiration.toUTCString()};`;
    }
    document.cookie = cookie;
  }
}
