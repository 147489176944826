import { Observable } from 'rxjs';
import { ApiVersion } from '../../../../core/enums/api-version';
import { ApiService } from '../../../../core/services/api.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';

export interface LocationTypeModel {
  id: number;
  name: string;
  identifier: string;
  isSystem: boolean;
  isProtected: boolean;
  isExternal: boolean;
  isDeleted: boolean;
  isScheduled: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  private cookieValue: string = '';

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    window.addEventListener('message', (event) => {
      console.log('message recived');
      var test = event;
      if (event.origin === 'https://devbox.dev-cl.com') {
        this.cookieValue = event.data;
      }
    });
  }

  makeApiCall(): any {
    let test = this.apiService
      .post<any, LocationTypeModel>(
        ApiVersion.V1,
        `LocationType/GetAllLocationTypes`
      )
      .subscribe((result) => {
        let balh = 123;
        let another_test = result;
        let what_ = 4345;
      });
  }

  makeV2ApiCall(): any {
    this.authService.getUserContext().subscribe((data) => {
      let blah = data;
    });
  }
}
