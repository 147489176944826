//TODO:
export const environment = {
  production: true,
  v1Api_url: '/api',
  v1Api_url_without_api: '/',
  v2Api_url: '/v2',
  // v1Api_url: 'https://customer.pandas.centrallogicdev.com/api',
  // v1Api_url_without_api: 'https://customer.pandas.centrallogicdev.com/'
  // v2Api_url: 'https://customer.pandas.centrallogicdev.com/v2'
  // test: 'https://{customerId}.cortex.centrallogicdev.com/api'
};
