import { ThemesComponent } from './features/play-ground/components/themes/themes.component';
import { PacHomeComponent } from './features/pac/components/pac-home/pac-home.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AutoCompleteComponent } from './features/play-ground/components/auto-complete/auto-complete.component';
import { HomeComponent } from './features/play-ground/components/home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', component: PacHomeComponent, canActivate: [AuthGuard] },
  { path: 'auto', component: AutoCompleteComponent, canActivate: [AuthGuard] },
  { path: 'themes', component: ThemesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
