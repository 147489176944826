import { Injectable } from "@angular/core";
import { NameFormat } from "../enums/name-format";

@Injectable({
  providedIn: 'root'
})
export class NameFormatter {

  format(firstName: string = '', middleName: string = '', lastName: string = '', type: NameFormat): string {
    let fullName = `${firstName} ${lastName}`;

    switch(type) { 
      case NameFormat.FirstLast: { 
        fullName = `${firstName} ${lastName}`;
        break; 
      }
      case NameFormat.FirstMiddleLast: {
        const mName = this.formattedMiddleName(middleName);
        fullName = `${firstName}${mName} ${lastName}`;
        break;
      }
      case NameFormat.FirstMiddleInitialLast: {
        const mi = this.formattedMiddleInitial(middleName);
        fullName = `${firstName}${mi} ${lastName}`;
        break;
      }
      case NameFormat.LastFirst: { 
        fullName = `${lastName}, ${firstName}`;
        break; 
      }
      case NameFormat.LastFirstMiddle: {
        const mName = this.formattedMiddleName(middleName);
        fullName = `${lastName}, ${firstName}${mName}`;
        break;
      }
      case NameFormat.LastFirstMiddleInitial: {
        const mi = this.formattedMiddleInitial(middleName);
        fullName = `${lastName}, ${firstName}${mi}`;
        break;
      }
    }
    return fullName;
  }

  formattedMiddleInitial(middleName: string = ''): string {
    return (middleName && middleName.length) ? ` ${middleName.substring(0, 1).toUpperCase()}.` : '';
  }

  formattedMiddleName(middleName: string = ''): string {
    return (middleName && middleName.length) ? ` ${middleName}` : '';
  }
}
