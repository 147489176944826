<tab-view>
  <tab-item>
    <tab-header> Search </tab-header>
    <tab-content>
      <pac-search></pac-search>
    </tab-content>
  </tab-item>
  <tab-item>
    <tab-header> Requests </tab-header>
    <tab-content>
      <p>More Content goes here</p>
    </tab-content>
  </tab-item>
</tab-view>
