import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiVersion } from 'src/app/core/enums/api-version';
import { ApiService } from 'src/app/core/services/api.service';
import { CustomerContext } from '../models/customer-context.model';
import { HelpContextRequest } from '../models/help-context-request.model';
import { HelpContextResponse } from '../models/help-context-response.model';

@Injectable({
  providedIn: 'root'
})
export class ContextService {

  constructor(private apiService: ApiService) { }

  public getCustomerContext(): Observable<CustomerContext> {
    let customerContextResponse = this.apiService.post<any, CustomerContext>(
      ApiVersion.V1,
      'SystemSetting/GetCustomerContext'
    );
    return customerContextResponse;
  }

  public getHelpContext(
    featureHelpId: number,
    helpId: number,
    docUrl: string
  ): Observable<HelpContextResponse> {
    let helpContextRequest = {helpId: helpId, featureHelpId: featureHelpId, featureId: featureHelpId} as HelpContextRequest;
    const usePathOnly: boolean = docUrl.startsWith('http');

    let helpContextResponse = this.apiService.post<HelpContextRequest, HelpContextResponse>(
      ApiVersion.noapiV1,
      docUrl,
      helpContextRequest,
      usePathOnly
    );
    return helpContextResponse;
  }
}
