import { NameFormatter } from './../../../../shared/helpers/name-formatter';
import { SelectedProvider, SendMessageDocument, SendReferralsRequest } from './../../models/send-referrals-request.model';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PostAccuteService } from '../../services/post-accute.service';
import { NameFormat } from 'src/app/shared/enums/name-format';

export interface ReferralDialogData {
  patient: any;
  transferRecordId: number;
  message: string;
  providers: any[];
  documents: any[];
}

@Component({
  selector: 'app-send-referral-dialog',
  templateUrl: './send-referral-dialog.component.html',
  styleUrls: ['./send-referral-dialog.component.scss']
})
export class SendReferralDialogComponent {

  patientName: string = '';
  errorMessage: string = '';
  step1Visible: boolean = true;
  step2Visible: boolean = false;
  step3Visible: boolean = false;

  constructor(
    private pacService: PostAccuteService,
    private nameFormatter: NameFormatter,
    public dialogRef: MatDialogRef<SendReferralDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReferralDialogData) {

    this.patientName = nameFormatter.format(data.patient.FirstName, data.patient.MiddleName, data.patient.LastName, NameFormat.LastFirstMiddleInitial);
  }

  getProviderNameAndLOC(provider: string) {
    var provObj = JSON.parse(provider);
    return provObj.Name + ' / ' + provObj.LevelOfCare;
  }
  onAddComments() {
    const requestData = {
      TransferRecordId: this.data.transferRecordId,
      Message: this.data.message,
      Providers: this.data.providers.map(x => {
        const providerObj = JSON.parse(x);
        const provId = providerObj.Id;
        const locId = providerObj.LevelOfCareId;
        return { Value: provId, LevelOfCareId: locId } as SelectedProvider;
      }),
      Documents: [] as SendMessageDocument[]
    } as SendReferralsRequest;

    this.pacService.sendReferrals(requestData).subscribe({
      next: (v) => {
        console.log('sendReferrals next:');
        console.log(v);
      },
      error: (e) => {
        console.log('sendReferrals error:');
        console.log(e);
        this.errorMessage = e.ExceptionMessage || e.Message;
      },
      complete: () => {
        console.log('sendReferrals complete');
      }
    });
  }
}
