<p>auto-complete works!</p>


<h1>Make Provider V1 API Call</h1>
<br />
<button (click)="makeProviderSearch()">Make Provider Call</button>
<div *ngFor="let provider of providerResponse">
  <div>Name: {{ provider.Name }}</div>
  <div>email: {{ provider.emailAddresses }}</div>
</div>
<br />


<h1>Angular Material Table Example</h1>
<br />
<div>
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="Name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let location"> {{ location.Name }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Id">
      <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
      <mat-cell *matCellDef="let location"> {{ location.Id }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Identifier">
      <mat-header-cell *matHeaderCellDef> Identifier </mat-header-cell>
      <mat-cell *matCellDef="let location">
        {{ location.Identifier }}
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
<br />

<h1>Angular Material Auto Complete Example</h1>
<br />
<form class="example-form">
  <input type="text"
         placeholder="Search for a Provider"
         [formControl]="control"
         [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let provider of providerResults | async" [value]="provider.Name">
      {{provider.Name}}
    </mat-option>
  </mat-autocomplete>
</form>
<br />